<template>
  <meta name="description" content="Welcome to Master Projekt! Based in Prizren, we specialize in comprehensive project services, 
  from design to construction. With over 130 professionals, 
  we bring your ideas to life with expertise and precision." />

  <div id="app">
  
    <header class="header">
      <img src="../assets/logo3.png" class="logoheader animate__animated animate__fadeIn">
      <div class="intro">
        <h1 class="animate__animated animate__fadeIn">{{$t('mainpagetext1')}}</h1>
        <p>
          {{$t('mainpagetext2')}}
        </p>
      </div>
      <div class="divider"></div>
    </header>
    <!-- Section with 130+ Zaposlenih -->
     <Clients/>
    <section class="section section-right reveal">
      <img src="https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/buildings-bg.jpg?alt=media&token=c5281fb3-0e1c-4614-b6e3-848b47fa7e42" alt="Section Image" class="background-image" />
      <div class="content content-large text-right">
        <h2 class="animate__animated animate__fadeIn">{{$t('mainpagetext3')}}</h2>
        <p class="animate__animated animate__fadeIn">
          {{$t('mainpagetext4')}}
        </p>
      </div>
    </section>

    <!-- Section with 5000+ Planova with different gallery -->
    <section class="section section-left reveal">
      <img src="https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/daniel-mccullough--FPFq_trr2Y-unsplash.jpg?alt=media&token=3477e695-42e3-4f2c-b547-1a72d3b4402c" alt="Section Image" class="background-image" />
      <div class="content content-large text-left">
        <h2 class="animate__animated animate__fadeIn">{{$t('mainpagetext5')}}</h2>
        <p class="animate__animated animate__fadeIn">
          {{$t('mainpagetext6')}}
        </p>
        <!-- Gallery Section -->
        <div class="gallery">
          <img v-for="image in planGallery" :src="image" :key="image" alt="Plan Image" class="gallery-image"/>
        </div>
      </div>
    </section>
    
    <!-- Section with 500+ Izgrađenih Objekata and a small gallery -->
    <section class="section section-right reveal">
      <img src="https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/employees-bg.jpg?alt=media&token=e0d55f4f-d3c5-4776-988a-466ac72836ca" alt="Section Image" class="background-image" />
      <div class="content content-large text-right">
        <h2 class="animate__animated animate__fadeIn">{{$t('mainpagetext7')}}</h2>
        <p class="animate__animated animate__fadeIn">
          {{$t('mainpagetext8')}}
        </p>
        <!-- Small gallery for this section -->
        <div class="mini-gallery">
          <img v-for="image in buildingGallery" :src="image" :key="image" alt="Building Image" class="mini-gallery-image"/>
        </div>
      </div>
    </section>


    <!-- Contact Section with Location and Contact Info -->
   <Footer/>
  </div>
</template>


<script>
import Footer from '@/components/Footer.vue'
import Clients from '@/components/Clients.vue'
export default {
  components: {
    Footer,Clients
  },
  mounted() {
    this.observeSections();
  },

  methods: {
    observeSections() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const animatedElements = entry.target.querySelectorAll('.animate__animated');
              animatedElements.forEach((el) => {
                el.classList.add('animate__fadeInUp');
              });
              entry.target.classList.add('reveal');
            }
          });
        },
        { threshold: 0.3 }
      );

      const sections = document.querySelectorAll('.section');
      sections.forEach((section) => {
        observer.observe(section);
      });
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Georgia', serif;
  background-color: #1a1a1a; /*#080404*/
  color: white;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #888 #333;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #333;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
  border: 2px solid #333;
}

.header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  position: relative;
  text-align: center;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/masterprojekt-ba7a5.appspot.com/o/image%20(1).jpg?alt=media&token=b27a7a08-e34a-4170-b46c-3cefe0b0a012');
  background-size: cover;
  background-position: center;
}

.header .intro h1 {
  margin-top: -12% !important;
  font-size: 3.5em;
  letter-spacing: 2px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.header .intro p {
  font-size: 1.2em;
  color: #ebe9e9;
}

.section {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  background-position: center;

  padding: 0 5%;
  position: relative;
  overflow: hidden;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.5s;
}

.section.reveal {
  opacity: 1;
  transform: translateX(0);
}



.header img{
  position: relative;
  top: -15.5% !important;
  width: 170px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(45%);
}


.content-large h2 {
  margin-top: -12% !important;
  font-size: 3.5em;
  letter-spacing: 2px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

.content-large p {
  font-size: 1.2em;
  color: #ccc;
  max-width: 600px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #555;
  position: absolute;
  bottom: 0;
  left: 0;
}

.contact-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: #333;
}

.contact-section h2 {
  font-size: 48px;
  margin-bottom: 20px;
}

.contact-section p {
  font-size: 24px;
  color: #ddd;
  margin-bottom: 40px;
}

.map-container {
  width: 600px;
  height: 450px;
  margin-top: 20px;
}

.location-info {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin-bottom: 20px;
}

.location-info .work-hours, .location-info .address {
  color: #ccc;
  text-align: left;
}

.location-info p {
  margin: 5px 0;
}



@media (max-width: 768px) {
  .header .intro p {
  font-size: 15px;
  color: #ebe9e9;
}
  }

</style>
