import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import ENG from './local/eng.json'
import BOS from './local/bos.json'
import SHQ from './local/shq.json'


const i18n = createI18n({
    locale: localStorage.getItem('language'),
    messages:{
        ENG:ENG,
        BOS:BOS,
        SHQ:SHQ,
        
    }
  })

createApp(App).use(router).use(i18n).mount('#app')
